<template>
  <v-form>
    <v-toolbar flat>
      <v-toolbar-title>{{ $t("login") }}</v-toolbar-title>
      <v-spacer />
    </v-toolbar>

    <v-card-text>
      <v-row>
        <v-col cols="12">
          <ValidationProvider :name="$t('login')" rules="required" slim>
            <template #default="{ errors, valid }">
              <span
                class="caption ml-2 grey--text text--darken-1"
                v-text="$t('login')"
              />
              <v-text-field
                v-model="username"
                :error-messages="errors"
                :success="valid"
                autocomplete="username"
                dense
                name="username"
                outlined
                required
                type="text"
                @keyup.enter="onLogin"
              >
                <template #append>
                  <icon-person-outline outlined size="1.5rem" />
                </template>
              </v-text-field>
            </template>
          </ValidationProvider>
        </v-col>

        <v-col cols="12">
          <ValidationProvider :name="$t('password')" rules="required" slim>
            <template #default="{ errors, valid }">
              <span
                class="caption ml-2 grey--text text--darken-1"
                v-text="$t('password')"
              />
              <v-text-field
                id="password"
                v-model="password"
                :autocomplete="false"
                :error-messages="errors"
                :success="valid"
                dense
                name="password"
                outlined
                required
                type="password"
                @keyup.enter="onLogin"
              >
                <template #append>
                  <icon-lock-closed-outline outlined size="1.5rem" />
                </template>
              </v-text-field>
            </template>
          </ValidationProvider>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-btn :to="{ name: 'account.forget' }" color="primary" text>
        {{ $t("forget.password") }}
      </v-btn>
      <v-spacer />
      <v-btn :loading="btnLoading" color="primary" rounded @click="onLogin">
        {{ $t("sign.in") }}
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import { LayoutModule } from "@/store/layout";
import { AuthModule } from "@/store/auth";
import AuthMixin from "@/mixins/AuthMixin";
import { Profile } from "@planetadeleste/vue-mc-shopaholic";
import { FlashModule } from "@/store/flash";

@Component({ name: "login-form" })
export default class LoginForm extends Mixins(AuthMixin) {
  username = "";
  password = "";
  btnLoading = false;

  @Prop(Boolean) readonly valid!: boolean;

  get profile() {
    const sProfile = localStorage.getItem("user");
    const obProfileData = sProfile ? JSON.parse(sProfile) : {};
    return new Profile(obProfileData);
  }

  clear() {
    this.username = "";
    this.password = "";
  }

  mounted() {
    this.$nextTick(() => {
      this.username = this.profile.get("email", "");
    });
  }

  async onLogin() {
    if (!this.valid) {
      return;
    }

    this.loading();
    await this.logIn(this.username.toLowerCase(), this.password).catch(
      (error) => {
        this.processError(error);
      }
    );

    if (AuthModule.isLogged) {
      this.onPreload();
    }

    this.loaded();
  }

  onPreload() {
    this.setLoading(true);
    if (AuthModule.isLogged && AuthModule.user) {
      FlashModule.showWelcome();
      LayoutModule.setLayout("init");
    }
  }

  async onLogout() {
    await this.logOut();
    await this.$router.push({ name: "home" });
  }
}
</script>
